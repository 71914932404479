import React from 'react';
import { Box } from '@chakra-ui/react'

class Footer extends React.Component {
    render() {
        const { locale } = this.props;
        const rssLink = locale === 'zh-hant' ? '/rss.xml' : `/${locale}/rss.xml`;

        return (
            <Box
                as="footer"
                mt={3}
                pt={1}
            >
                <div style={{ float: 'right' }}>
                    <a href={rssLink} target="_blank" rel="noopener noreferrer">
                        rss
                    </a>
                </div>
                <a
                    href="https://twitter.com/ctxhou"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    twitter
                </a>{' '}
                &bull;{' '}
                <a
                    href="https://github.com/ctxhou"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    github
                </a>{' '}
            </Box>
        );
    }
}

export default Footer;
